import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squats w/:02 Pause at bottom 4×4\\@65%1RM (fast on ascent)`}</p>
    <p>{`Glute Ham Raise 4×4 w/:02 Paust at bottom (fast on ascent)`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each:`}</p>
    <p>{`Back Squat (from floor) 135/95`}</p>
    <p>{`K2E’s`}</p>
    <p>{`Box Jumps (24/20″)`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      